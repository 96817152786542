@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.header {
    text-align: center;
    padding: 24px 0 16px;

    @include media-query-grid(sm) {
        text-align: left;
        padding-top: 0;
    }
}

.copy {
    display: flex;
    height: 48px;
    position: relative;
}

.copy-input {
    flex: 1;

    .input-tag {
        text-overflow: ellipsis;
        overflow: hidden;
        border-color: color($grey, 500);
        border-radius: 3px 0 0 3px;
        border-right: 0;
        line-height: 30px; /* to match the button */
    }
}

.copy-button {
    border-radius: 0 3px 3px 0;
    border-color: color($grey, 500);
}

.copy-notification {
    width: 70%;
    height: 46px;
    display: block;
    background: $white;
    position: absolute;
    z-index: 0;
    top: 1px;
    left: 1px;
    line-height: 46px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: color($grey, 900);
    animation: .2s ease-out;
    animation-name: fade-out;
    animation-fill-mode: both;
    animation-delay: 1s;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
