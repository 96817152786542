@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '@fiverr-private/sass-assets/colors-v2';
@import '@fiverr-private/sass-assets/mixins';

.referral-program-perseus {
    background: $white;

    .page-section {
        padding: 32px 20px;

        @include media-query-grid(sm) {
            padding: 55px 20px;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 27px;
        color: $text_base;
        text-align: center;
        margin: 0 auto 20px;
        text-transform: none !important;

        @include media-query-grid(sm) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 30px;
        }
    }
}
