@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$facebook_color: #3d5899;
$twitter_color: #1da1f3;
$google_color: #d14c3e;
$linkedin_color: #0677b6;

.social-icon {
    font-size: 15px;
    font-weight: 700;
    width: 49px;
    height: 49px;
    border: 1px solid;
    border-radius: 3px;
    margin: 0 13px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-query-grid(sm) {
        margin: 0 16px 0 0;
    }

    @include media-query-grid(md) {
        margin-right: 23px;
    }

    @include media-query-grid(xl) {
        width: 145px;
        margin-right: 8px;
    }

    &:hover {
        text-decoration: none;
        color: $white;

        svg path {
            fill: $white;
        }
    }
}

.social-label {
    display: none;

    @include media-query-grid(xl) {
        display: inline;
        margin-left: 16px;
    }
}

.facebook {
    color: $facebook_color;
    border-color: $facebook_color;

    &:hover {
        background-color: $facebook_color;
    }
}

.twitter {
    color: $twitter_color;
    border-color: $twitter_color;

    &:hover {
        background-color: $twitter_color;
    }
}

.google {
    color: $google_color;
    border-color: $google_color;

    &:hover {
        background-color: $google_color;
    }
}

.linkedin {
    color: $linkedin_color;
    border-color: $linkedin_color;

    &:hover {
        background-color: $linkedin_color;
    }
}
