@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$box_height: 48px;
$alert_color: #f74040;

.form {
    display: flex;
    flex-direction: column;

    @include media-query-grid(sm) {
        flex-direction: row;
        flex: 1;
        grid-gap: 12px;
    }
}

.email {
    margin-bottom: 12px;

    @include media-query-grid(sm) {
        flex: 1;
        margin-bottom: 0;
    }
}

.email-input {
    width: 100%;
    margin-bottom: 8px;

    .input-tag {
        border-color: color($grey, 500);
        line-height: 30px; /* to match the button */

        &-alert {
            border-color: $alert_color;
        }
    }
}

.notification {
    height: 18px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: $text_base_lighter;

    @include media-query-grid(sm) {
        text-align: left;
    }

    @include media-query-grid(md) {
        height: 23px;
    }
}

.submit-button {
    height: $box_height;
    width: 100%;

    @include media-query-grid(sm) {
        margin-bottom: 8px;
    }
}

.preview {
    display: none;

    @include media-query-grid(sm) {
        display: block;
    }
}

.email-preview {
    cursor: pointer;
    color: $link_blue;
    font-size: 15px;

    &:hover {
        color: $link_blue;
    }
}
