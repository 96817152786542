@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$table_max_width: 1080px;
$gradient: hsla(0deg, 0%, 100%, .5);

.referral-program-perseus .page-section.track-referrals {
    text-align: center;
    max-width: $table_max_width;
    margin: 0 auto;

    .expand-container {
        max-height: 225px;
        overflow: hidden;
        transition: max-height .4s;
        transform: translate3d(0, 0, 0);

        &.show {
            max-height: 3000px;
        }

        @include media-query-grid(sm) {
            max-height: 250px;
        }
    }

    a.show-all {
        color: $link_blue;
        font-size: 15px;
        line-height: 23px;
        background: linear-gradient($gradient, $white 75%, $white);
        width: 100%;
        display: inline-block;
        padding-top: 30px;
        top: -20px;
        position: relative;

        &:hover {
            color: $link_blue;
        }

        @include media-query-grid(sm) {
            font-size: 17px;
            line-height: 27px;
        }
    }

    table {
        width: 95%;
        text-align: left;
        margin: 0 auto;

        thead tr {
            display: none;

            @include media-query-grid(sm) {
                display: table-row;
                border-bottom: 1px solid $border_grey_med;
            }
        }

        th {
            padding-bottom: 15px;
        }

        th,
        td {
            padding-top: 15px;

            &:first-child {
                @include media-query-grid(sm) {
                    padding-left: 20px;
                }

                @include media-query-grid(md) {
                    padding-left: 40px;
                }
            }

            &:last-child {
                display: none;

                @include media-query-grid(sm) {
                    display: table-cell;
                    width: 100px;
                    padding-right: 20px;
                    box-sizing: border-box;
                }

                @include media-query-grid(md) {
                    padding-right: 40px;
                }
            }

            &.status {
                text-align: right;

                @include media-query-grid(sm) {
                    text-align: left;
                }

                .sent i {
                    background: $border_grey_med;
                }

                .registered i {
                    background: $link_green;
                }

                .granted i {
                    background: $stars_gold;
                }

                i {
                    background: $border_grey_med;
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }

        h3 {
            color: $text_base_medium;
            font-size: 21px;
            line-height: 28px;
            font-weight: 700;
        }

        p {
            color: $text_base;
        }
    }

    .table-content {
        font-size: 15px;
        line-height: 23px;
    }
}
