@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.join {
    margin-top: 15px;

    @include media-query-grid(sm) {
        margin-top: 30px;
    }
}
