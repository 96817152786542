@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$xl: 1400px;
$coudinary_prefix: 'https://fiverr-res.cloudinary.com/q_auto/general_assets/referral_program/assets/f1/banner_background_';

.referral-program-perseus .referral-page-header {
    background-color: $black;
    background-image: url($coudinary_prefix + 'xs.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 300px;

    @include media-query-grid(sm) {
        background-image: url($coudinary_prefix + 'sm.jpg');

        &.logged-in {
            height: 490px;
        }
    }

    @include media-query-grid(md) {
        background-image: url($coudinary_prefix + 'md.jpg');
    }

    @include media-query-grid(lg) {
        background-image: url($coudinary_prefix + 'lg.jpg');
    }

    @media only screen and (min-width: $xl) {
        @include media-query-grid(lg) {
            background-image: url($coudinary_prefix + 'xl.jpg');
        }
    }

    h1,
    p {
        color: $white;
    }

    h1 {
        padding-top: 20px;

        @include media-query-grid(sm) {
            padding-top: 64px;
        }
    }

    > p {
        display: inline-block;
        padding-top: 20px;
        max-width: 270px;

        @include media-query-grid(sm) {
            font-size: 20px;
            line-height: 140%;
            max-width: none;
        }
    }

    label + h1 {
        padding-top: 20px;
    }
}
