@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$invite_max_width: 1080px;
$black_20pct: rgba(0, 0, 0, .2);

.invite {
    background-color: $white;
    max-width: $invite_max_width;
    box-sizing: border-box;
    border-bottom: 1px solid $border_grey_light;

    @include media-query-grid(sm) {
        border-radius: 3px;
        border-bottom: none;
        box-shadow: 0 2px 4px 0 $black_20pct;
        position: relative;
        width: 100%;
        padding: 32px 20px;
    }

    @include media-query-grid(md) {
        padding: 48px;
    }
}

.invite-by-email {
    padding-bottom: 24px;
    border-bottom: 1px solid $border_grey_light;
}

.copy-social {
    display: flex;
    flex-direction: column;

    @include media-query-grid(sm) {
        flex-direction: row;
        padding-top: 24px;
    }

    @include media-query-grid(md) {
        padding-top: 32px;
    }
}

.copy-invite {
    flex: 1;

    @include media-query-grid(sm) {
        max-width: 431px;
    }
}

.social-share {
    padding-top: 32px;
    padding-bottom: 40px;

    @include media-query-grid(sm) {
        padding: 0 0 0 15px;
    }

    @include media-query-grid(md) {
        padding-left: 48px;
    }
}
