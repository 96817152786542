@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '~@fiverr-private/sass/helpers/index';

.modal {
    // Notice!!!
    // Givin bigger priority to override fit max-width
    &.desktop {
        @include media-query-grid(sm) {
            max-width: 800px;
        }
    }
}
