@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$terms_max_width: 837px;

.referral-program-perseus .terms {
    text-align: center;

    @include media-query-grid(md) {
        max-width: $terms_max_width;
        margin: 0 auto;
    }

    p.desc {
        @include media-query-grid(sm) {
            font-size: 17px;
            line-height: 27px;
        }
    }

    a.read {
        color: $link_blue;
        font-size: 15px;
        line-height: 23px;

        &:hover {
            color: $link_blue;
        }

        @include media-query-grid(sm) {
            font-size: 17px;
            line-height: 27px;
        }
    }

    .full-terms {
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s;
        transform: translate3d(0, 0, 0);

        &.show {
            max-height: 3000px;
        }

        h3 {
            border-top: 1px solid $border_grey_light;
            font-size: 20px;
            line-height: 120%;
            font-weight: 700;
            padding: 30px 0 10px;
            margin-top: 30px;
            width: 100%;
            text-align: left;
        }

        h4 {
            font-size: 16px;
            line-height: 120%;
            font-weight: 700;
            margin: 20px 0 10px;
        }

        p {
            text-align: left;
        }
    }
}
