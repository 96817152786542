@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.page-section {
    padding: 32px 20px;

    @include media-query-grid(sm) {
        padding: 55px 20px;
    }
}

.invite-holder {
    background-color: transparent;
    padding-bottom: 0;

    @include media-query-grid(sm) {
        margin-top: -240px; // shifts the block up
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    @include media-query-grid(md) {
        padding: 0 32px;
    }

    @include media-query-grid(xl) {
        padding: 0 340px;
    }
}
