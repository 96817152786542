@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$steps_max_width: 837px;
$steps_base_padding: 55px;

.referral-program-perseus .page-section.steps-to-invite {
    padding-top: $steps_base_padding;
    padding-bottom: $steps_base_padding / 2;
    background-color: $bg_offwhite;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        display: flex;
        flex-direction: column;
        max-width: $steps_max_width;

        @include media-query-grid(sm) {
            flex-direction: row;
            justify-content: space-between;
        }

        li {
            position: relative;
            padding: 10px 0 10px 58px;

            @include media-query-grid(sm) {
                padding: 55px 0 0;
                max-width: 30%;
                text-align: center;
            }

            @include media-query-grid(sm) {
                padding-top: 65px;
            }

            @include media-query-grid(md) {
                padding-top: 80px;
            }

            &::before {
                content: ' ';
                background: transparent url('../../assets/steps-to-invite-sm.svg') no-repeat 0 0;
                background-size: 102px 34px;
                display: block;
                position: absolute;
                z-index: 0;
                top: 0;
                left: 10px;
                width: 34px;
                height: 34px;

                @include media-query-grid(sm) {
                    top: 13px;
                    left: calc(50% - 17px);
                }

                @include media-query-grid(md) {
                    background: transparent url('../../assets/steps-to-invite-md.svg') no-repeat 0 0;
                    background-size: 129px 43px;
                    width: 43px;
                    height: 43px;
                }
            }

            &:nth-child(2)::before {
                background-position: -34px 0;

                @include media-query-grid(md) {
                    background-position: -43px 0;
                }
            }

            &:nth-child(3)::before {
                background-position: -68px 0;

                @include media-query-grid(md) {
                    background-position: -86px 0;
                }
            }
        }
    }

    p {
        @include media-query-grid(sm) {
            font-size: 17px;
            line-height: 27px;
        }
    }
}
