@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.header {
    text-align: center;
    padding-bottom: 16px;

    @include media-query-grid(sm) {
        text-align: left;
    }
}

.social-items {
    display: flex;
    justify-content: center;

    @include media-query-grid(sm) {
        justify-content: left;
    }
}

.social-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
