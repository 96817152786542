@import '../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.holder {
    overflow: hidden;
    border-radius: 50%;
}

.image {
    width: 100px;
    height: 100px;
    display: block;
}

.without-photo {
    display: block;
    background-color: #c2c2c2;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    height: 100px;
    width: 100px;
    line-height: 100px;
}
